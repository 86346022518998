// Import our CSS
import "@/scss/app.scss";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */

import { menu } from "./components/menu.js";
import Alpine from "alpinejs";
import AOS from "aos";
import collapse from "@alpinejs/collapse";
import { scrollLink } from "./components/scrollLink.js";

Alpine.plugin(collapse);

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});

window.Alpine = Alpine;
Alpine.start();
menu();
scrollLink();

// animation
AOS.init();
